import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Button as BootstrapButton } from 'react-bootstrap'; 
import { useDropzone } from 'react-dropzone'; 
import moment from "moment";
import axios from "axios";
function Giglerquestions() {

  const baseUrl ="https://app.gigleji.com"
  const initialValues = {
    name: '',
    email: '',
    phoneNumber:'',
    resumeFile: null,
  };

  const generatePasswordWithName = (name) => {
    return (
      name.charAt(0).toUpperCase() +
      name.slice(1) +
      "@" +
      moment().format("YYYY")
    );
  };


  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required!'),
    email: Yup.string().email('Invalid Email').required('Required!'),
    phone: Yup.string().max(10).min(10).required('Required!'),
    resumeFile: Yup.mixed().required('Required!'),
  });
//  const submit_data= (values,setSubmitting) => {
//       let temp1 = values.name;
//       let temp2 = values.email;
//       let temp3 = values.resumeFile;
//       console.log("resume", temp3);
//       const nameArry = temp1.split(" ");
//       const fiName = nameArry[0];
//       const laName = nameArry.slice(1).join(" ");
//       const pass = generatePasswordWithName(fiName);

//       console.log(laName,fiName,nameArry,pass)
//       const gigTalent = {
//         firstName: fiName,
//         lastName: laName,
//         email: temp2,
//         password: pass,
//         type: "gigler",
//         createdBy: "GijlejiWeb",
//       };
//       console.log(gigTalent)
//       axios
//         .post(`${baseUrl}/api/users/`, gigTalent)
//         .then((result) => {
//           if (result) {
//             const gigId = result?.data?.result?.data?.id;
//             const register = {
//               userId: gigId,
//               firstName: fiName,
//               lastName: laName,
//               email: temp2,
//             };
//             axios
//               .post(`${baseUrl}/api/userInfo/create`, register)
//               .then((data) => {
//                 if (data) {
//                   const uId = data?.data?.result?.data?.userId;
//                   const fileData = new FormData();
//                   fileData.append("resume", temp3);
//                   fileData.append("id", uId);
//                   axios
//                     .post(`${baseUrl}/api/userUploads/resume`, fileData)
//                     .then((resumedata) => {
//                       if (resumedata) {
//                         alert("Resume Successfully uploaded");
//                       }
//                     })
//                     .catch((err) => {
//                       alert(err);
//                     });
//                 }
//               })
//               .catch((err) => {
//                 alert(err);
//               });
//           }
//         })
//         .catch((err) => {
//           alert(err);
//         });
//         // setSubmitting(false)
//         // // resetForm();
//         // setOpenDialog(true);
//     }
  const handleUpload = async (values, { setSubmitting, resetForm, setFieldValue }) => {
    // Here you can implement your upload logic
    // For simplicity, let's just display console logs
    let temp1 = values.name;
    let temp2 = values.email;
    let temp3 = values.resumeFile;
    let temp4 = values.phone;
    const nameArry = temp1;
    const fiName = temp1;
    const laName =temp1;
    const pass = generatePasswordWithName(fiName);
    const gigTalent = {
      firstName: fiName,
      lastName: laName,
      email: temp2,
      phoneNumber:temp4,
      password: pass,
      type: "gigler",
      createdBy: "GijlejiWeb",
    };

   await axios
      .post(`${baseUrl}/api/users/`, gigTalent)
      .then((result) => {
        if (result) {
          const gigId = result?.data?.result?.data?.id;
          const register = {
            userId: gigId,
            firstName: fiName,
            lastName: laName,
            email: temp2,
            phoneNumber:temp4
          };
         axios
            .post(`${baseUrl}/api/userInfo/create`, register)
            .then((data) => {
              if (data) {
                const uId = data?.data?.result?.data?.userId;
                const fileData = new FormData();
                fileData.append("resume", temp3);
                fileData.append("id", uId);
                axios
                  .post(`${baseUrl}/api/userUploads/resume`, fileData)
                  .then((resumedata) => {
                    if (resumedata) {
                      alert("Resume Successfully uploaded");

                    }
                  })
                  .catch((err) => {
                    alert(err);
                  });
                  setOpenDialog(true); 

              }
            })
            .catch((err) => {
              console.log("data",err)
              alert(err);
            });
            setSubmitting(false);
            
        }
      })
      .catch((err) => {
        console.log("res",)
       
        alert("Email Already Exist!!, Try with different email.");
      });
    console.log('Uploading...', values);
    resetForm(); 

  };

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const redirectToGigleji = () => {
    window.location.href = baseUrl;
  };

  // Dropzone component
  const Dropzone = ({ setFieldValue }) => {
    const [fileName, setFileName] = React.useState('');

    const { getRootProps, getInputProps } = useDropzone({
      accept: 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/rtf',
      onDrop: (acceptedFiles) => {
        const file = acceptedFiles[0];
        setFieldValue('resumeFile', file);
        setFileName(file.name); 
      }
    });

    return (
      <div {...getRootProps()} style={{ marginBottom: '10px', padding: '20px', border: '2px dashed #aaa', borderRadius: '4px' }}>
        <input {...getInputProps()} />
        {fileName ? (
          <p>{fileName}</p>
        ) : (
          <p>Drag & drop your resume file here, or click to select file<br></br>
            <span className='text-danger'>Note: Choose a PDF file</span>
          </p>
        )}
      </div>
    );
  };

  return (
    <div
      style={{
        background: 'linear-gradient(90deg, rgba(246,243,243,1) 6%, rgba(196,22,22,1) 46%, rgba(196,22,22,1) 61%)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: 'calc(100vh - 64px - 56px)', // Height minus navbar and footer height
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        filter: openDialog ? 'blur(15px)' : 'none', 
      }}
    >
      <div
        className="container"
        style={{
          backgroundColor: '#eeee',
          padding: '20px',
          maxWidth: '400px',
          marginTop: '70px',
          marginBottom:'20px'
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleUpload}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form style={{ textAlign: 'center', color: '#000' }}>
              <h5><b>Unleash Your <span style={{ color: 'red' }}>Talents!</span></b></h5>
              <h6>Let employers discover your incredible skills in minutes. Showcase your portfolio, and start meaningful conversations.</h6>
              <h6>Get rewarded for your talents, and receive payment when your clients are thrilled with your exceptional work!</h6>
              <Field
                as={TextField}
                name="name"
                label="Name"
                variant="outlined"
                margin="normal"
                fullWidth
                style={{ marginBottom: '10px' }}
              />
              <ErrorMessage name="name" component="div" style={{ color: 'red' }} />
              <Field
                as={TextField}
                name="phone"
                label="Phone"
                variant="outlined"
                margin="normal"
                type="number"
                fullWidth
                style={{ marginBottom: '10px' }}
              />
              <ErrorMessage name="phone" component="div" style={{ color: 'red' }} />
              <Field
                as={TextField}
                name="email"
                label="Email"
                variant="outlined"
                margin="normal"
                fullWidth
                style={{ marginBottom: '10px' }}
              />
              <ErrorMessage name="email" component="div" style={{ color: 'red' }} />
              {/* Replace input field with Dropzone */}
              <Dropzone setFieldValue={setFieldValue} />
              <ErrorMessage name="resumeFile" component="div" style={{ color: 'red' }} />
              {/* Replace Material-UI Button with Bootstrap Button */}
              <BootstrapButton
                variant="contained"
                type="submit"
                disabled={isSubmitting}
                fullWidth
                style={{ marginBottom: '10px', backgroundColor: 'red', color:'white' }}
              >
                {isSubmitting ? 'Uploading...' : 'Upload Resume'}
              </BootstrapButton>
            </Form>
          )}
        </Formik>
      </div>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Thank You!</DialogTitle>
        <DialogContent>
          <p>Your resume has been uploaded successfully. You'll receive an email shortly.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
          <Button onClick={redirectToGigleji} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Giglerquestions;
